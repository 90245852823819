  <template>
  <div class="flex flex-col justify-between h-screen">
    <div
      class="flex items-center content-center h-screen text-white bg-[#0f0f0f] bg-no-repeat bg-cover bg-center"
      style="background-image: url('/Error404.jpg')"
    >
      <div
        class="absolute inset-0 bg-[#0f0f0f] bg-opacity-50 mix-blend-multiply z-10"
      />
      <div class="relative z-20 pt-20 mx-auto space-y-8 text-center max-w-7xl">
        <div>
          <div class="flex items-center justify-center">
            <Svg404 class="w-40 md:w-auto text-primary" />
          </div>

          <div class="min-h-[80px]">
            <h1
              v-if="!store.isLoading"
              class="w-full max-w-xl px-10 mx-auto text-xl font-semibold text-center md:text-3xl md:px-0 font-zuume"
            >
              {{ $t("common.page_not_found_homepage") }}
            </h1>
            <a
              v-if="!store.isLoading"
              :href="toDefaultPage"
              class="inline-block mt-5 text-lg italic font-semibold uppercase transition-all duration-200 md:text-xl text-primary hover:no-underline font-zuume"
            >
              {{ $t("common.return_to_home") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Svg404 from "~/components/404NotFound.vue";
import { useMainStore } from "@/store/index";

const props = defineProps({
  error: Object,
});
const nuxtApp = useNuxtApp();
const store = useMainStore();
// const CountryLocale = useCookie("CountryLocale");
const CountryLocale = nuxtApp.$cookies.set("CountryLocale");

onServerPrefetch(async () => {
  if (CountryLocale) {
    await store.loadTranslationJsonData(CountryLocale.replace("/", "-"));
  } else {
    await store.loadTranslationJsonData("int-en");
  }
});

const toDefaultPage = computed(() => {
  if (CountryLocale) {
    return `/${CountryLocale.replace("/", "-")}`;
  }
  return "/";
});
definePageMeta({
  layout: "blank",
});
</script>